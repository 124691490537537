@import '../../styles/variables.scss';
@import '../../styles/responsive.scss';

.image-modal {
	background: rgba(0, 0, 0, 0.9);
	display: block;
	z-index: 100;
	display: flex;
	justify-content: center;
	align-items: center;

	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;

	.close-icon {
		color: $white;
		cursor: pointer;
		font-size: 50px;

		@include phone-down {
			position: fixed;
			right: 25px;
			top: 25px;
		}

		@include tablet-up {
			position: absolute;
			right: -50px;
			top: -50px;
		}
	}

	.image-modal-inner {
		position: relative;
	}
}
