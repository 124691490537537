ul.bulleted {
	padding-left: 12px;
	margin-bottom: 10px;
}

li.bulleted-item {
	border: none;
	margin: 2px 0;
	min-height: auto;
	list-style: disc;
}
