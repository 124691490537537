@import '../../../styles/variables.scss';

.hoki-skateboards {
	padding: 20px;
}

.hoki-skateboards-inner {
	margin: 0 auto;
	max-width: 600px;
}

.main-image {
	margin-bottom: 10px;
	display: block;
	margin: 0 auto 10px;
}
