@import '../../styles/mixins.scss';
@import '../../styles/responsive.scss';
@import '../../styles/variables.scss';

.pannels {
	@include tablet-up {
		display: flex;
	}

	flex-direction: row-reverse;

	.pannel:first-child {
		@include phone-down {
			margin-bottom: 20px;
		}
	}

	&.reverse {
		@include tablet-up {
			flex-direction: row;
		}
	}
}
