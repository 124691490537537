@import '../../styles/mixins.scss';
@import '../../styles/responsive.scss';
@import '../../styles/variables.scss';

section.list-section {
	ul {
		@include phone-down {
			display: block;
			margin: 0 auto;
			max-width: 340px;
		}

		li {
			font-size: 16px;
			margin-bottom: 15px;
		}
	}
}
