.filter-form {
	display: flex;

	i {
		font-size: 26px;
		line-height: 60px;
		text-align: center;
		width: 50px;
	}
}
