@import '../../../styles/variables.scss';

.hoki-skateboards-inner-content {
	margin-bottom: 20px;

	p.button-link {
		background-color: $blue-three;
		border-radius: 8px;
		font-weight: bold;
		padding: 5px;
		text-align: center;
		text-transform: uppercase;
		max-width: 200px;
		display: block;
		margin: 0 auto 20px;
	}

	a {
		text-decoration: none;
	}

	a:hover p.button-link {
		color: white;
	}

	img.product-image {
		height: auto;
		margin: 0 auto 10px;
		max-width: 300px;
		width: 100%;
	}
}
