@import '../../styles/mixins.scss';
@import '../../styles/responsive.scss';

.trailhead {
	position: relative;

	h2 {
		font-size: 24px;
	}

	h3 {
		font-size: 20px;
	}

	h2,
	h3 {
		margin: 0;
		text-align: left;
	}

	p,
	a {
		margin: 0;
		font-size: 12px;
		max-width: 600px;
	}

	$breakpoint1: 400px;
	$breakpoint2: 768px;

	.trailhead-image {
		@media (max-width: $breakpoint1) {
			display: block;
			margin: 0 auto 10px;
		}
		@media (min-width: $breakpoint1) and (max-width: $breakpoint2) {
			float: left;
			margin-bottom: 15px;
			margin-right: 15px;
		}
		@media (min-width: $breakpoint2) {
			left: 0;
			position: absolute;
		}
	}

	.earned {
		margin-bottom: 10px;

		@media (min-width: $breakpoint2) {
			margin-left: 200px;
		}
	}

	.rank {
		@media (min-width: $breakpoint1) and (max-width: $breakpoint2) {
			clear: both;
		}
		@media (min-width: $breakpoint2) {
			margin-left: 200px;
		}
	}
}
