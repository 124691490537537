ul.social-media {
	display: flex;
	justify-content: space-between;
	list-style: none;
	margin: 0 auto 20px;
	padding: 0;
	width: 200px;

	li {
		font-size: 30px;
	}
}
