ul.staggered {
	margin: 0;
	padding: 0;

	li.staggered-item {
		list-style: none;
		margin: 25px 0;
		min-height: 200px;
		display: flex;

		@include phone-down {
			display: block;
			justify-content: center;
			margin: 0 auto 40px;
			max-width: 600px;
		}

		&:not(:last-child) {
			@include phone-down {
				border-bottom: solid 1px $gray-one;
			}
		}

		.image {
			display: block;
			overflow: hidden;
			margin: 0 auto;

			@include phone-down {
				height: 250px;
				margin-bottom: 20px;
				width: 250px;
			}

			@include tablet-up {
				flex: 1 0 200px;
				height: 200px;
				width: 200px;
			}
		}

		.text {
			@include phone-down {
				margin-bottom: 20px;

				h2 {
					margin: 0;
					width: 100%;
				}
			}

			@include tablet-up {
				flex: 100%;
				padding: 10px 20px;
			}

			h2,
			h3 {
				text-align: left;
			}
		}

		@include tablet-up {
			&:nth-child(even) {
				flex-direction: row-reverse;
			}

			&:nth-child(odd) {
				flex-direction: row;
			}
		}
	}
}
