@import '../../styles/mixins.scss';
@import '../../styles/responsive.scss';
@import '../../styles/variables.scss';

#container.resume {
	h2 {
		text-align: center;
	}

	.sub-line {
		margin-bottom: 10px;
		overflow: hidden;

		@include tablet-up {
			display: flex;
			justify-content: space-between;
		}

		h4 {
			font-size: 18px;
			text-align: left;
			margin: 0;
		}

		.date {
			margin: 0;

			@include phone-down {
				color: $gray-one;
				font-size: 14px;
			}

			@include tablet-up {
				display: flex;
				justify-content: space-between;
			}
		}
	}
}
