.tech-list-horizontal {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	list-style-type: none;
	margin: 0;
	padding: 0;

	li {
		margin-right: 10px;
	}
}
