@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/responsive.scss';

.menu-btn {
	@include tablet-up {
		display: none;
	}

	.mobileMenuIcon {
		cursor: pointer;
		font-size: 30px;
		line-height: 60px;
		position: absolute;
		right: 20px;
		top: 0;
	}
}

.navigation {
	$fullMobileNavHeight: 645px;
	$mobileNavAnimSpeed: 0.7s;
	display: block;
	margin: 0;
	padding: 0;
	list-style: none;

	@include phone-down {
		li:last-child {
			margin-bottom: 15px;
		}

		@include transition(max-height $mobileNavAnimSpeed);

		&.open {
			max-height: $fullMobileNavHeight;
		}

		&:not(.open) {
			max-height: 0;
		}
	}

	@include tablet-up {
		float: right;
		display: flex;
	}

	li {
		line-height: 60px;

		@include phone-down {
			display: block;
		}
	}

	a {
		display: block;
		font-size: 16px;
		font-weight: bold;
		padding: 0 10px;
		text-decoration: none;

		&:focus,
		&:hover,
		&.active {
			background: $primary;
			color: white;
		}
	}
}
