form {
	margin-top: 20px;

	textarea {
		min-height: 150px;
	}

	.form-group {
		margin-bottom: 15px;
	}

	label {
		margin-bottom: 5px;
		font-weight: bold;
	}

	input,
	textarea {
		&.form-control {
			background: $gray-two;
			border: none;
			color: $gray-one;
			font-size: 16px;
			height: 60px;
			padding: 10px 20px;

			&:focus {
				background: $gray-two;
			}
		}
	}

	.alert {
		border: none;

		&.alert-danger {
			color: $red-one;
			background: $red-two;
		}
	}
}
