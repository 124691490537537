@mixin opacity($opacity: 0.5) {
	-webkit-opacity: $opacity;
	-moz-opacity: $opacity;
	opacity: $opacity;
}

@mixin border-radius($radius: 2px) {
	border-radius: $radius;
	-moz-border-radius: $radius;
	-webkit-border-radius: $radius;
	-o-border-radius: $radius;
}

@mixin transition($transition) {
	-moz-transition: $transition;
	-webkit-transition: $transition;
	-o-transition: $transition;
	-ms-transition: $transition;
	transition: $transition;
}

@mixin transform($string) {
	-webkit-transform: $string;
	-moz-transform: $string;
	-ms-transform: $string;
	-o-transform: $string;
}

@mixin box-shadow($string) {
	-webkit-box-shadow: $string;
	-moz-box-shadow: $string;
	box-shadow: $string;
}

@mixin text-shadow($string: 0 1px 3px rgba(0, 0, 0, 0.25)) {
	text-shadow: $string;
}

@mixin no-select() {
	-webkit-user-select: none; /* Chrome all / Safari all */
	-moz-user-select: none; /* Firefox all */
	-ms-user-select: none; /* IE 10+ */
	user-select: none;
}

@mixin pull-right {
	float: right;
}

@mixin clearfix() {
	&:before,
	&:after {
		content: ' ';
		display: table;
	}

	&:after {
		clear: both;
	}
}
