@import '../../styles/variables.scss';

.software-icon {
	$iconW: 40px;
	height: $iconW;
	width: $iconW;
}

$iconW: 40px;

.software-icon-holder {
	height: ($iconW + 2);
	margin-right: 10px;
	width: ($iconW + 2);
}
