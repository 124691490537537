@import '../../styles/mixins.scss';
@import '../../styles/responsive.scss';
@import '../../styles/variables.scss';

#container.projects {
	$iconW: 40px;

	.answer-section {
		margin: 10px 0 0 20px;

		ul.answer-section-list {
			padding: 0 0 0 20px;
		}
	}

	.find-section {
		max-width: 400px;
		margin: 0 auto 20px;

		.filter-item {
			overflow: hidden;
		}

		input {
			@include border-radius(0);
			@include box-shadow(none);
			border-bottom: solid 1px $gray-three;
		}

		form {
			#input-holder {
				height: 60px;
				position: relative;

				&.hasSearchTerm {
					padding-right: 30px;
				}

				i {
					cursor: pointer;
					font-size: 26px;
					line-height: 60px;
					position: absolute;
					right: 0;
					top: 0;
				}
			}
		}
	}

	ul.projects {
		list-style: none;
		margin: 0 auto 60px;
		overflow: hidden;
		padding: 0;
	}

	.list-item {
		border-top: solid 1px $gray-one;
		margin: 20px 0;
		padding: 20px 0;

		.projectHeading {
			margin-bottom: 20px;

			h2 {
				font-size: 30px;
			}

			h3 {
				font-size: 20px;
			}

			h2,
			h3 {
				text-align: center;
				margin: 0;
				padding: 0;
			}
		}

		p {
			&.list-heading {
				margin: 10px 0 0;
			}
		}

		img {
			&.project-img {
				@include phone-down {
					display: block;
					margin: 0 auto;
				}
			}

			&.logo {
				@include border-radius(50%);
				height: 80px;
				margin-bottom: 20px;
				width: 80px;

				@include phone-down {
					margin-top: 20px;
				}
			}
		}

		.btn {
			max-width: 200px;
			margin: 20px auto 0;
			display: block;
		}
	}

	ul.projects {
		&.results-1 {
			max-width: 250px;

			.grid-item {
				width: 100%;
			}
		}

		&.results-2 {
			max-width: 500px;

			.grid-item {
				width: 50%;
			}
		}

		&.results-3 {
			max-width: 750px;

			.grid-item {
				width: 33.33333333%;
			}
		}

		&.results-2,
		&.results-3,
		&.results-full {
			@media (max-width: 349px) {
				max-width: 250px;

				.grid-item {
					width: 100%;
				}
			}

			@media (min-width: 350px) and (max-width: 499px) {
				max-width: 500px;

				.grid-item {
					width: 50%;
				}
			}
		}

		&.results-full {
			@media (min-width: 500px) and (max-width: 767px) {
				max-width: 750px;

				.grid-item {
					width: 33.33333333%;
				}
			}

			@include tablet-up {
				.grid-item {
					@include tablet-up {
						width: 25%;
					}
				}
			}
		}
	}

	.grid-item {
		float: left;
		padding: 4px;
		position: relative;

		img.project-img {
			display: block;
			margin: 0 auto;
		}

		.popup {
			display: none;
			bottom: 0;
			cursor: pointer;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;

			.popup-inner {
				height: 100%;
				position: relative;
				width: 100%;

				.popup-cont {
					bottom: 0;
					left: 0;
					position: absolute;
					right: 0;
					top: 40px;
					z-index: 1;

					img {
						@include border-radius(50%);
						display: block;
						height: 80px;
						margin: 0 auto;
						width: 80px;
					}

					a {
						display: block;
						margin: 10px auto 0;
						width: 60px;
					}
				}

				.popup-overlay {
					@include opacity(0.8);
					background: $gray-three;
					bottom: 0;
					left: 0;
					position: absolute;
					right: 0;
					top: 0;
				}
			}
		}

		&:hover > .popup {
			display: block;
		}

		.project-link {
			color: $white;
			font-size: 20px;
			font-weight: bold;
			margin-top: 10px;
			text-align: center;
			text-decoration: underline;
		}
	}

	.projects-container {
		padding-top: 20px;

		@include phone-down {
			margin: 0 10px;
		}

		@include tablet-up {
			margin: 0 auto;
		}

		&.grid {
			max-width: 800px;
		}

		&.list {
			max-width: 600px;
		}
	}

	.no-results {
		margin: 30px 0;
		text-align: center;

		h3 {
			font-size: 30px;
			margin: 0 0 15px 0;
		}

		p {
			cursor: pointer;
			font-size: 16px;
		}
	}

	p.results-count,
	p.view-controls {
		font-size: 16px;
		margin-top: 10px;
	}

	p.results-count {
		float: left;
	}

	p.view-controls {
		cursor: pointer;
		text-align: right;
	}
}
