@import '../../styles/mixins.scss';
@import '../../styles/responsive.scss';
@import '../../styles/variables.scss';

ul.social-icons {
	align-items: center;
	display: flex;
	gap: 10px;
	justify-content: center;
	list-style: none;
	margin: 0;
	padding: 0;

	li {
		&:last-child {
			margin: 0;
		}

		i {
			&:hover {
				background: $primary;
			}
		}
	}
}
