@import '../../styles/mixins.scss';
@import '../../styles/responsive.scss';
@import '../../styles/variables.scss';

.nav-title {
	line-height: 60px;
	margin: 0;
	padding: 0;
	color: $white;
	text-decoration: none;
	font-weight: bold;
	font-size: 35px;
}
