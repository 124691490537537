section.tech-tiles {
	ul {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin: 0 auto;
		padding: 0;
		max-width: 600px;

		li {
			height: 40px;
			list-style: none;
			margin: 5px;
			width: 40px;
		}
	}
}
