@import '../../styles/mixins.scss';
@import '../../styles/responsive.scss';
@import '../../styles/variables.scss';

section.education {
	img.picture {
		margin-bottom: 5px;

		@include phone-down {
			margin: 0px auto 10px;
		}
	}

	p {
		font-size: 24px;
		margin: 0;

		&.degree {
			font-weight: bold;
		}

		&.image-caption {
			font-size: 14px;
			color: $gray-three;
		}
	}
}

.text-block {
	margin-bottom: 20px;
}

img.school {
	@include phone-down {
		max-width: 200px;
	}

	@include tablet-up {
		max-width: 250px;
	}
}

img.picture {
	@include phone-down {
		max-width: 100%;
	}

	@include tablet-up {
		max-width: 600px;
	}
}
