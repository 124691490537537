@import '../../../styles/mixins.scss';
@import '../../../styles/responsive.scss';
@import '../../../styles/variables.scss';

.home-banner {
	align-items: center;
	display: flex;
	justify-content: center;
	position: relative;

	@include phone-down {
		flex-direction: column;
		padding: 40px 0;
	}

	@include tablet-up {
		flex-direction: row;
		height: 350px;
	}

	img.banner-pic {
		border-radius: 80%;
		height: 100%;

		@include phone-down {
			height: 250px;
			margin-bottom: 20px;
		}

		@include tablet-up {
			height: 300px;
			margin-right: 20px;
		}
	}

	p.job-title {
		font-size: 30px;
		font-weight: bold;
		margin: 0;
		margin-bottom: 10px;
	}

	p.banner-name {
		font-size: 20px;
		margin: 0;
		text-align: center;
	}

	.btn {
		display: block;
		margin: 0 auto;

		@include phone-down {
			margin-top: 10px;
			width: 150px;
		}

		@include tablet-up {
			width: 200px;
			margin-top: 20px;
		}
	}

	.social-icons {
		margin: 0 auto 20px;
	}
}
