@import '../../styles/mixins.scss';
@import '../../styles/responsive.scss';
@import '../../styles/variables.scss';

section.education-component {
	img {
		margin: 20px auto;

		@include phone-down {
			max-width: 250px;
		}
	}

	p {
		font-size: 24px;
		margin: 0;

		&.degree {
			font-weight: bold;
		}
	}
}
