@import '../../../styles/variables.scss';

.tech-list-vertical {
	display: block;
	list-style: none;
	padding: 0;
	margin: 0;

	li {
		margin-bottom: 10px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	$iconW: 40px;

	.tech-icon-holder {
		float: left;
	}

	.tech-icon {
		margin-right: 10px;
	}

	.tech-icon-item {
		overflow: hidden;

		p {
			float: left;
			margin: 0;
		}

		p,
		i {
			line-height: $iconW;
		}
	}
}
