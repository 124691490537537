a {
	h4 {
		color: white;
	}
}

h4 {
	text-align: center;
	font-size: 26px;
}
