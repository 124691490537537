@mixin phone-down {
	@media (max-width: 767px) {
		@content;
	}
}

@mixin tablet-up {
	@media (min-width: 768px) {
		@content;
	}
}
