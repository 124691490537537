@import '../../../styles/variables.scss';

.app-section {
	max-width: 512px;
	margin: 0 auto 20px;

	.app-icon {
		display: block;
		margin: 0 auto 10px;
	}

	.app-badges {
		align-items: center;
		display: flex;
		justify-content: center;

		img {
			width: auto;
		}
		img.apple-badge {
			height: 50px;
		}
		img.google-badge {
			height: 75px;
		}
	}

	p.copyright {
		color: $gray-one;
		font-size: 10px;
		margin-top: 20px;
		text-align: center;
	}
}
