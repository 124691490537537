i {
	&.circ-sm {
		@include border-radius(50%);
		background: $white;
		color: $gray-two;
		cursor: pointer;
		height: 30px;
		line-height: 30px;
		text-align: center;
		width: 30px;

		&:hover {
			background: $gray-two;
			color: $white;
		}
	}

	&.fa-close {
		color: $red-two;
	}
}
