// Black and WHite
$white: #ffffff;
$black: #000000;

// Grays
$gray-one: #eeeeee;
$gray-two: #444444;
$gray-three: #333333;

// Green
$green-one: #39b54c;
$green-two: #1a733b;

// Reds
$red-one: #f2dede;
$red-two: #a94442;

// Blues
$blue-one: #7c8fce;
$blue-two: #5a6fb4;
$blue-three: #415082;
$blue-four: #27314f;
$blue-five: #1b2136;

// Colors
$primary: $blue-three;
$secondary: $blue-four;
