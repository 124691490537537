@import '../../styles/variables.scss';

#subfooter {
	background: $gray-two;
	overflow: hidden;

	.contact-info {
		margin: 10px;

		@media (min-width: 500px) {
			float: left;
		}
	}

	.social-icons {
		float: left;
		height: 40px;
		line-height: 40px;
	}

	a.contact-link {
		color: $white;
		line-height: 40px;
		margin-left: 20px;
	}
}
