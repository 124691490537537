@import './responsive.scss';

body {
	background: $gray-three;
	color: $white;
}

.pointer {
	cursor: pointer;
}

.center {
	text-align: center;
}

.f-left {
	float: left;
}

.f-right {
	float: right;
}

ul.disc {
	list-style: disc;
}

.m-l-20 {
	margin-left: 20px;
}

p.indent {
	text-indent: 20px;
}

a {
	cursor: pointer;
	color: $white;
}

p,
a,
li {
	font-size: 16px;
}

div,
section {
	&.center {
		text-align: center;
	}

	&.bold {
		font-weight: bold;
	}
}

img {
	max-width: 100%;

	&.center {
		margin: 0 auto;
		display: block;
	}
}

section {
	padding: 20px;

	&.white {
		background: $white;
		color: $gray-two;
	}

	&.primary {
		background: $primary;
		color: $white;
	}

	&.gray {
		background: $gray-two;
		color: $white;
	}

	h3 {
		font-weight: bold;
		margin: 0 0 20px;
		text-align: center;

		@include phone-down {
			font-size: 25px;
		}

		@include tablet-up {
			font-size: 30px;
		}
	}
}

.boxed {
	max-width: 1024px;
	margin: 0 auto;
	padding: 0 10px;

	&.boxed-sm {
		max-width: 300px;
	}

	&.boxed-md {
		max-width: 600px;
	}

	&.boxed-lg {
		max-width: 900px;
	}
}

h1 {
	font-weight: bold;
	margin: 30px 0;
	text-align: center;
}

img.round {
	border-radius: 100%;
}
