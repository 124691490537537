.btn {
	&.btn-primary {
		background: $primary;
		border: none;

		&:hover {
			background: $secondary;
		}
	}

	&.btn-secondary {
		border: solid 3px $secondary;
		background: $secondary;

		&:hover {
			border: solid 3px $secondary;
			background: $primary;
		}
	}
}
