.timpanogos {
	p.label {
		font-weight: bold;
		text-align: left;
		font-size: 14px;
	}

	p.activities {
		font-size: 14px;
		text-align: left;
	}
}
